import FeaturedListGroup from 'components/FeaturedListGroup'
import ScrollToTopOnMount from 'components/ScrollToTopOnMount'
import featured from 'data/featured.json'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

const Browse = ({ section, title }) => {
    return (
        <>
            <Helmet>
                <meta name="robots-explo" content="noindex" />
            </Helmet>
            <ScrollToTopOnMount />
            <FeaturedListGroup all section={featured[section]} title={title} />
        </>
    )
}

Browse.propTypes = {
    section: PropTypes.oneOf(['pages', 'sections']).isRequired,
    title: PropTypes.string.isRequired,
}

export default Browse
