import CardLink from 'components/CardLink'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Button, Col, Row, Stack } from 'react-bootstrap'
import { Link, useSearchParams } from 'react-router-dom'
import { v5 as uuidv5 } from 'uuid'

dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(advancedFormat)
const MY_UUID_NAMESPACE = '03b2bf9b-857c-4d81-89b7-67a7d26928ac'

const PREVIEW_LIMIT = 3

const FeaturedListGroup = ({ section, title, toMore, children, all }) => {
    const [searchParams] = useSearchParams()
    const showAll = searchParams.get('all') || all
    const dateOfView = dayjs(searchParams.get('date') || undefined)

    // Overly complicated, but predictably random.
    const featuredSorter = o => {
        const string = dateOfView.format(`ggggww[${o.path}]`)
        const uuid = uuidv5(string, MY_UUID_NAMESPACE)

        let hash = 0
        for (let i = 0; i < uuid.length; i++) {
            const ch = uuid.charAt(i)
            if (ch !== '-') {
                hash = (hash << 4) + parseInt(ch, 16)
                hash = hash % Math.pow(2, 16)
            }
        }

        return _.toInteger(hash * (_.has(o, 'image') ? 1.25 : 1))
    }

    let rows
    if (showAll) {
        rows = _.sortBy(section, 'title')
    } else {
        rows = _.filter(section, x => _.get(x, 'title', '').length > 0)
        rows = _.sortBy(rows, featuredSorter)
        rows = _.chunk(rows, PREVIEW_LIMIT)[0]
    }

    return (
        <section>
            <h2>{title}</h2>
            <Stack className="my-2" gap={3}>
                {children && <div>{children}</div>}
                <Row className="gap-2 gap-lg-0">
                    {_.map(rows, row => {
                        const { path, title, summary, image } = row

                        return (
                            <Col className="my-2" key={path} xs={12} lg={4} data-hash={featuredSorter(row)}>
                                <CardLink
                                    href={path}
                                    image={image}
                                    title={<span dangerouslySetInnerHTML={{ __html: title }} />}
                                    summary={<span dangerouslySetInnerHTML={{ __html: summary }} />}
                                />
                            </Col>
                        )
                    })}
                </Row>
                {toMore && (
                    <div>
                        <Button as={Link} to={toMore} variant="secondary">
                            Show More <span className="visually-hidden">{title}</span>
                            <i className="bi bi-chevron-right" />
                        </Button>
                    </div>
                )}
            </Stack>
        </section>
    )
}

FeaturedListGroup.defaultProps = {
    all: false,
}

FeaturedListGroup.propTypes = {
    all: PropTypes.bool.isRequired,
    section: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string.isRequired,
            title: PropTypes.string,
            summary: PropTypes.string,
            image: PropTypes.string,
        }).isRequired
    ).isRequired,
    title: PropTypes.string.isRequired,
    toMore: PropTypes.string,
}

export default FeaturedListGroup
