import ScrollToTopOnMount from 'components/ScrollToTopOnMount'
import _ from 'lodash'
import React from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { ClearRefinements, InfiniteHits, RefinementList } from 'react-instantsearch'

import CustomCurrentRefinements from '../../components/CustomCurrentRefinements'
import SearchHit from '../../components/SearchHit'
import styles from './Search.module.css'

const RefinementListDropdown = () => {
    const refinements = [
        {
            label: 'Language',
            attribute: 'language',
            listLabel: 'Languages',
            operator: 'and',
        },
        {
            label: 'Collection',
            attribute: 'collection_name',
            listLabel: 'Collections',
            operator: 'or',
        },
    ]

    return (
        <Dropdown>
            <Dropdown.Toggle id="search-dropdown" variant="outline-white" title="Filter">
                Filter
                <div className="visually-hidden">Search Results</div>
            </Dropdown.Toggle>
            <Dropdown.Menu renderOnMount className="p-4 shadow">
                {_.map(refinements, ({ label, attribute, listLabel, operator }) => (
                    <div key={attribute} className="mb-3">
                        <h3 className="h5">{label}</h3>
                        <RefinementList
                            attribute={attribute}
                            aria-label={listLabel}
                            operator={operator}
                            classNames={{
                                root: 'border border-1',
                                list: 'list-group list-group-flush',
                                item: 'list-group-item list-group-item-action text-nowrap',
                                label: `${styles.refinementLabel} w-100`,
                                labelText: 'me-1',
                                count: 'badge text-bg-dark rounded-pill',
                                selectedItem: 'active',
                                checkbox: 'd-none',
                            }}
                        />
                    </div>
                ))}
                <ClearRefinements
                    classNames={{
                        button: 'btn btn-outline-white',
                        disabledButton: 'disabled',
                    }}
                    translations={{
                        resetButtonText: 'Clear Filter',
                    }}
                />
            </Dropdown.Menu>
        </Dropdown>
    )
}

const Search = () => {
    return (
        <>
            <Helmet>
                <meta name="robots-explo" content="noindex" />
            </Helmet>
            <ScrollToTopOnMount />
            <div className="mb-5">
                <ScrollToTopOnMount />
                <h2>Search Results</h2>
                <Row direction="horizontal" className="align-items-center my-3">
                    <Col xs="auto" className="">
                        <RefinementListDropdown />
                    </Col>
                    <Col>
                        <CustomCurrentRefinements
                            classNames={{
                                list: 'gap-2',
                                category: 'mx-1',
                                delete: `${styles['btn-xs']} ms-1 btn-text`,
                                labelText: 'ms-1 me-2 fs-6 fw-normal',
                            }}
                        />
                    </Col>
                </Row>
                <InfiniteHits
                    showPrevious={false}
                    classNames={{
                        list: `${styles.hitsList} gap-2 gap-lg-0 row`,
                        item: `${styles.hitsItem} my-2 col-lg-4 col-12`,
                        loadMore: 'btn btn-secondary my-2',
                        disabledLoadMore: 'btn btn-secondary my-2',
                    }}
                    translations={{
                        showMoreButtonText: 'Show More',
                    }}
                    hitComponent={SearchHit}
                />
            </div>
        </>
    )
}

export default Search
