import useBreakpoints from 'hooks/use-breakpoints'
import React from 'react'
import { Stack } from 'react-bootstrap'

import ContentContainer from '../ContentContainer'
import styles from './Footer.module.css'

const Footer = () => {
    const { xsDown } = useBreakpoints()
    const direction = xsDown ? 'vertical' : 'horizontal'

    return (
        <footer className="footer border-top">
            <ContentContainer className={`${styles.footer} small`}>
                <p>The Exploratorium is a 501(c)(3) nonprofit organization. Our tax ID #: 94-1696494</p>
                <Stack className="justify-content-center" direction={direction} gap={2}>
                    <a href="https://www.exploratorium.edu/about/use-policy">
                        © {new Date().getFullYear()} Exploratorium
                    </a>
                    {direction === 'horizontal' && <div className="vr" />}
                    <a href="https://www.exploratorium.edu/about/use-policy">Terms of Service</a>
                    {direction === 'horizontal' && <div className="vr" />}
                    <a href="https://www.exploratorium.edu/about/privacy-policy">Privacy Policy</a>
                    {direction === 'horizontal' && <div className="vr" />}
                    <a href="https://www.exploratorium.edu/about/privacy-policy#california">
                        Your California Privacy Rights
                    </a>
                </Stack>
            </ContentContainer>
        </footer>
    )
}

export default Footer
