import FeaturedListGroup from 'components/FeaturedListGroup'
import ScrollToTopOnMount from 'components/ScrollToTopOnMount'
import featured from 'data/featured.json'
import React from 'react'
import { Stack } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const Home = () => {
    return (
        <>
            <Helmet>
                <meta name="robots-explo" content="noindex" />
            </Helmet>
            <ScrollToTopOnMount />
            <Stack gap={4}>
                <FeaturedListGroup section={featured.pages} toMore="/featured-pages" title="Featured Pages" />
                <FeaturedListGroup section={featured.sections} toMore="/featured-sections" title="Featured Sections" />
            </Stack>
        </>
    )
}

export default Home
