import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

import logo from './explo-logo-white.svg'
import styles from './Header.module.css'

const Header = () => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const element = _.first(document.getElementsByTagName('body'))

        if (open) element.classList.add('mobile-menu-active')
        else element.classList.remove('mobile-menu-active')
    }, [open])

    const handleNavbarToggle = openState => {
        console.log('navbar open?', openState)
        setOpen(openState)
    }

    return (
        <>
            <header role="banner" className="header border-bottom">
                <Navbar
                    expanded={open}
                    variant="dark"
                    expand="lg"
                    bg="black"
                    aria-labelledby="block-mainnavigation-menu"
                    onToggle={handleNavbarToggle}
                >
                    <Container>
                        <Navbar.Toggle
                            aria-controls="navbarNav"
                            className={`navbar-toggler--mobile border-0 ${open ? 'navbar-toggler--active' : ''}`}
                        >
                            <div className="navbar-toggler__hamburger-container">
                                <span />
                                <span />
                                <span />
                            </div>
                        </Navbar.Toggle>
                        <Navbar.Brand
                            href="https://www.exploratorium.edu/"
                            aria-label="exploratorium home of the main website"
                        >
                            <img className={styles.logo} alt="Exploratorium" src={logo} />
                        </Navbar.Brand>
                        <Navbar.Collapse id="navbarNav" className="justify-content-between">
                            <Nav as="nav" role="navigation">
                                <h2 className="visually-hidden" id="block-mainnavigation-menu">
                                    Main navigation
                                </h2>
                                <ul className="menu__wrap menu__wrap--root navbar-nav">
                                    <li className="menu__item nav-item">
                                        <NavLink
                                            to="/"
                                            className={isActive => 'nav-link ' + (isActive ? 'active' : '')}
                                            onClick={() => handleNavbarToggle(false)}
                                        >
                                            Home
                                        </NavLink>
                                    </li>
                                    <li className="menu__item nav-item">
                                        <Nav.Link href="https://www.exploratorium.edu/visit">Visit</Nav.Link>
                                    </li>
                                    <li className="menu__item nav-item">
                                        <Nav.Link href="https://www.exploratorium.edu/support">Join + Support</Nav.Link>
                                    </li>
                                    <li className="menu__item nav-item">
                                        <Nav.Link href="https://www.exploratoriumstore.com/">Store</Nav.Link>
                                    </li>
                                </ul>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
            <div className="mobile-overlay" onClick={() => handleNavbarToggle(false)} />
        </>
    )
}

export default Header
