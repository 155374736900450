import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Breadcrumbs = ({ crumbs }) => {
    const iteratee = ({ label, to }, index) => {
        const classNames = `breadcrumb-item ${!to ? 'active' : ''}`
        return (
            <li key={label} className={classNames}>
                {to && <Link to={to}>{label}</Link>}
                {!to && <span>{label}</span>}
                {index + 1 < crumbs.length && <i className="bi bi-chevron-right mx-3 small" />}
            </li>
        )
    }
    return (
        <div id="block-breadcrumbs">
            <Breadcrumb className="breadcrumb-container container">{_.map(crumbs, iteratee)}</Breadcrumb>
        </div>
    )
}

Breadcrumbs.propTypes = {
    crumbs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            to: PropTypes.string,
        }).isRequired
    ).isRequired,
}

export default Breadcrumbs
