import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Highlight } from 'react-instantsearch'

import CardLink from '../CardLink'
import styles from './SearchHit.module.css'

const SearchHit = ({ hit }) => {
    const hasDescription = _.get(hit, '_highlightResult.description')

    const className = 'h-100 text-decoration-none text-white card-clickable ' + styles.hit
    return (
        <CardLink
            className={className}
            href={hit.url}
            image={hit.imageUrl}
            title={<span dangerouslySetInnerHTML={{ __html: hit.title }} />}
            summary={hasDescription ? <Highlight attribute="description" hit={hit} /> : undefined}
            data-insights-object-id={hit.objectID}
            data-insights-position={hit.__position}
            data-insights-query-id={hit.__queryID}
        />
    )
}

SearchHit.propTypes = {
    hit: PropTypes.object.isRequired,
    sendEvent: PropTypes.func,
}
export default SearchHit
