import _ from 'lodash'
import React, { Fragment } from 'react'
import { Badge, Button, Col, Row, Stack } from 'react-bootstrap'
import { useCurrentRefinements } from 'react-instantsearch'

const Refinement = ({ canRefine, refinement, onDelete: handleDelete, classNames }) => {
    const handleClick = () => {
        handleDelete(refinement)
    }

    return (
        <>
            <div className="vr" />
            <Button variant="white" onClick={handleClick} className={_.get(classNames, 'delete')}>
                <Stack gap={1} direction="horizontal">
                    <div key={refinement.label} className={_.get(classNames, 'categoryLabel')}>
                        {refinement.label}
                    </div>
                    <i className="bi bi-x"></i>
                </Stack>
            </Button>
        </>
    )
}

const RefinementsBadge = ({ item, ...props }) => {
    const { canRefine, refine } = useCurrentRefinements(props)
    const label = item.label === 'collection_name' ? 'Collection' : _.startCase(item.label)

    const handleDelete = refinement => {
        console.log('handleDelete', refinement)
        refine(refinement)
    }

    const classNames = _.get(props, 'classNames')

    return (
        <Badge bg="white" text="dark" className={_.get(classNames, 'item')}>
            <Stack direction="horizontal" gap={1} className={_.get(classNames, 'label')}>
                <div className={_.get(classNames, 'labelText')}>{label}</div>
                {_.map(item.refinements, refinement => {
                    return (
                        <Refinement
                            key={refinement.label}
                            canRefine={canRefine}
                            refinement={refinement}
                            classNames={classNames}
                            onDelete={handleDelete}
                        />
                    )
                })}
            </Stack>
        </Badge>
    )
}

const CustomCurrentRefinements = props => {
    const { items } = useCurrentRefinements(props)

    if (_.size(items) === 0) {
        return <Fragment></Fragment>
    }

    return (
        <div className={props?.classNames?.root}>
            <Row className={props?.classNames?.list}>
                {_.map(items, item => {
                    return (
                        <Col key={item.label} xs="auto">
                            <div className="overflow-x-auto">
                                <RefinementsBadge item={item} {...props} />
                            </div>
                        </Col>
                    )
                })}
            </Row>
        </div>
    )
}

export default CustomCurrentRefinements
