import _ from 'lodash'
import React, { useEffect } from 'react'
import { PoweredBy, SearchBox, useSearchBox } from 'react-instantsearch'
import { useLocation } from 'react-router-dom'

import styles from './SearchBox2.module.css'
function SearchBox2({ searchAsYouType }) {
    const location = useLocation()
    const { query, clear, refine } = useSearchBox()

    useEffect(
        () => {
            if (location.pathname !== '/search' && !_.isEmpty(query)) {
                console.log('algolia SearchBox2 clear', location.pathname, query)
                clear()
                refine('')
            }
        },
        // "query" intentionally left out of dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [clear, location.pathname, refine]
    )

    return (
        <div>
            <SearchBox
                searchAsYouType={searchAsYouType}
                aria-label="Search"
                placeholder="Find more to explore"
                classNames={{
                    form: 'mt-4 align-items-baseline input-group',
                    input: 'form-control',
                    submit: 'btn btn-outline-primary',
                    reset: 'btn btn-outline-primary',
                }}
                resetIconComponent={({ classNames }) => (
                    <>
                        <i className={`${classNames.resetIcon}  bi bi-x-lg`}></i>
                        <div className="visually-hidden">Reset Search Form</div>
                    </>
                )}
                submitIconComponent={({ classNames }) => (
                    <>
                        <i className={`${classNames.searchIcon} bi bi-search`}></i>
                        <div className="visually-hidden">Search</div>
                    </>
                )}
                loadingIconComponent={({ classNames }) => (
                    <>
                        <i className={`${classNames.loadingIcon} bi bi-hourglass`}></i>
                        <div className="visually-hidden">Loading Search Results</div>
                    </>
                )}
            />
            <PoweredBy theme="dark" className={`${styles.algoliaLogo} mt-1 mb-4`} />
        </div>
    )
}

export default SearchBox2
