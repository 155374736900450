import './oppenheimer/style.scss'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { BrowserRouter } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import App from './App'
import reportWebVitals from './reportWebVitals'

let algoliaUserToken = window.localStorage.getItem('algoliaUserToken')
if (_.isNil(algoliaUserToken)) {
    algoliaUserToken = uuid()
    window.localStorage.setItem('algoliaUserToken', algoliaUserToken)
}

const tagManagerArgs = {
    gtmId: _.get(process.env, 'REACT_APP_GTM_ID'),
    dataLayer: {
        algoliaUserToken,
    },
}

TagManager.initialize(tagManagerArgs)

Sentry.init({
    dsn: _.get(process.env, 'REACT_APP_SENTRY_DSN', ''),
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: _.toNumber(_.get(process.env, 'REACT_APP_SENTRY_TRACE_SAMPLE_RATE', 0)),
    environment: _.get(process.env, 'REACT_APP_SENTRY_ENVIRONMENT', 'local'),
    beforeSend(event, hint) {
        // Check if it is an exception, and if so, show the report dialog
        if (event.exception) {
            Sentry.showReportDialog({ eventId: event.event_id })
        }
        return event
    },
})

Sentry.setUser({ id: algoliaUserToken, ip_address: '{{auto}}' })

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Sentry.ErrorBoundary>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
