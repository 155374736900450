import algoliasearch from 'algoliasearch/lite'
import Breadcrumbs from 'components/Breadcrumbs'
import ContentContainer from 'components/ContentContainer'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { history } from 'instantsearch.js/es/lib/routers'
import _ from 'lodash'
import Browse from 'pages/Browse'
import Home from 'pages/Home'
import NotFound from 'pages/NotFound'
import Search from 'pages/Search'
import React from 'react'
import { Configure, InstantSearch } from 'react-instantsearch'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import SearchBox2 from './components/SearchBox2'

const ALGOLIA_APP_ID = _.get(process.env, 'REACT_APP_ALGOLIA_APP_ID')
const ALGOLIA_API_KEY = _.get(process.env, 'REACT_APP_ALGOLIA_API_KEY')
const ALGOLIA_SEARCH_INDEX = _.get(process.env, 'REACT_APP_ALGOLIA_SEARCH_INDEX')

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY)

const flattenObject = (obj, prefix = '') =>
    _.reduce(
        obj,
        (acc, val, key) => {
            if (_.isObject(val)) {
                _.extend(acc, flattenObject(val, prefix + key + '.'))
            } else {
                acc[prefix + key] = val
            }
            return acc
        },
        {}
    )

const App = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const parseSearch = search => (search.startsWith('?') ? search.slice(1) : '')

    const routing = {
        router: history({
            createURL({ qsModule, location, routeState }) {
                const indexState = routeState[ALGOLIA_SEARCH_INDEX] || {}
                const flattenIndexState = flattenObject(indexState)
                const queryString = qsModule.stringify(flattenIndexState)

                let createdUrl
                if (_.isEmpty(indexState.query) && !_.isObject(indexState.refinementList)) {
                    createdUrl = `${location.pathname}${location.hash}`
                } else {
                    createdUrl = `/search?${queryString}${location.hash}`
                }

                return createdUrl
            },
            parseURL({ qsModule, location }) {
                const search = parseSearch(location.search)
                const indexState = {}
                const params = new URLSearchParams(search)

                for (let param of params) {
                    _.set(indexState, param[0], param[1])
                }

                return {
                    [ALGOLIA_SEARCH_INDEX]: indexState,
                }
            },
            push(uri) {
                if (uri.startsWith('/search')) {
                    navigate(uri)
                }
            },
        }),
    }

    const userToken = window.localStorage.getItem('algoliaUserToken')

    return (
        <InstantSearch searchClient={searchClient} indexName={ALGOLIA_SEARCH_INDEX} routing={routing}>
            <Configure
                analytics
                clickAnalytics
                hitsPerPage={12}
                attributesToHighlight={['description:200']}
                userToken={userToken}
            />
            <Header />
            <Routes>
                <Route path="/" element={<React.Fragment />} />
                <Route
                    path="featured-pages"
                    element={<Breadcrumbs crumbs={[{ label: 'Home', to: '/' }, { label: 'Featured Pages' }]} />}
                />
                <Route
                    path="featured-sections"
                    element={<Breadcrumbs crumbs={[{ label: 'Home', to: '/' }, { label: 'Featured Sections' }]} />}
                />
                <Route
                    path="search"
                    element={<Breadcrumbs crumbs={[{ label: 'Home', to: '/' }, { label: 'Search' }]} />}
                />
                <Route path="*" element={<Breadcrumbs crumbs={[{ label: 'Home', to: '/' }]} />} />
            </Routes>
            <ContentContainer as="main" id="main-content" className="main-content">
                <SearchBox2 searchAsYouType={location.pathname === '/search'} />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="index.html" element={<Navigate replace to="/" />} />
                    <Route path="index.htm" element={<Navigate replace to="/" />} />
                    <Route path="featured-pages" element={<Browse section="pages" title="Browse Featured Pages" />} />
                    <Route
                        path="featured-sections"
                        element={<Browse section="sections" title="Browse Featured Sections" />}
                    />
                    <Route path="search" element={<Search />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </ContentContainer>
            <Footer />
        </InstantSearch>
    )
}

export default App
