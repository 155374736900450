import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { useLocation } from 'react-router-dom'

import ScrollToTopOnMount from '../components/ScrollToTopOnMount'

const NotFound = () => {
    const location = useLocation()

    let phpPage = undefined
    let pathname = location.pathname
    if (pathname.match(/\.php\b/)) {
        pathname = pathname.replace('.php', '.html')
        phpPage = pathname + location.search + location.hash
    }

    useEffect(() => {
        if (phpPage) {
            // Fallback for meta httpEquiv="refresh"
            const timeout = setTimeout(() => {
                window.location = phpPage
            }, 4000)

            return () => clearTimeout(timeout)
        }
    }, [phpPage])

    return (
        <div>
            <ScrollToTopOnMount />
            <p>404 - Not Found...</p>
            <div id="wb404" />
            <Helmet>
                <script src="https://archive.org/web/wb404.js" type="text/javascript" />
                {/* IMPORTANT: This meta tag is tied to our, annex's, indexer. */}
                <meta name="robots" content="noindex" />
                {phpPage && <meta httpEquiv="refresh" content={`3; url=${phpPage}`} />}
            </Helmet>
            {phpPage && (
                <p>
                    Page has moved to a new location <a href={phpPage}>{pathname}</a>
                </p>
            )}
        </div>
    )
}

export default NotFound
