import { useEffect, useState } from 'react'

const BREAKPOINTS = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
}

const useBreakpoints = () => {
    const [breakpoint, setBreakpoint] = useState()
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        const listener = event => {
            setWidth(event.target.window.innerWidth)
        }

        window.addEventListener('resize', listener)

        return () => {
            window.removeEventListener('resize', listener)
        }
    }, [])

    useEffect(() => {
        if (width >= BREAKPOINTS.xxl) setBreakpoint('xxl')
        else if (width >= BREAKPOINTS.xl) setBreakpoint('xl')
        else if (width >= BREAKPOINTS.lg) setBreakpoint('lg')
        else if (width >= BREAKPOINTS.md) setBreakpoint('md')
        else if (width >= BREAKPOINTS.sm) setBreakpoint('sm')
        else setBreakpoint('xs')
    }, [width])

    return {
        width,
        breakpoint,
        xsDown: width < BREAKPOINTS.sm,
        smDown: width < BREAKPOINTS.sm,
        mdDown: width < BREAKPOINTS.md,
        lgDown: width < BREAKPOINTS.lg,
        xlDown: width < BREAKPOINTS.xl,
        xxlDown: true,
        xsUp: true,
        smUp: width >= BREAKPOINTS.sm,
        mdUp: width >= BREAKPOINTS.md,
        lgUp: width >= BREAKPOINTS.lg,
        xlUp: width >= BREAKPOINTS.xl,
        xxlUp: width >= BREAKPOINTS.xxl,
    }
}

export default useBreakpoints
