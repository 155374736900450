import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Card, Stack } from 'react-bootstrap'

import styles from './CardLink.module.css'

const CardLink = ({ href, image, title, subtitle, summary, className, horizontal, ...props }) => {
    const classNames = `${_.isNil(className) ? '' : className} ${styles.cardLink} card-clickable`
    const [loaded, setLoaded] = useState(null)

    const imageUrl = image ? image.replace('%PUBLIC_URL%', process.env.PUBLIC_URL) : false

    let imageClassname = 'd-none'
    if (_.isNil(loaded)) {
        imageClassname = ''
    } else if (loaded) {
        imageClassname = 'visible'
    }

    return (
        <Card text="white" className={classNames} as="a" href={href} {...props}>
            <Stack
                direction={horizontal ? 'horizontal' : 'vertical'}
                className={horizontal ? 'align-items-start' : undefined}
            >
                <Card.Img
                    className={imageClassname}
                    role="presentation"
                    variant="top"
                    src={imageUrl}
                    onLoad={() => setLoaded(true)}
                    onError={() => setLoaded(false)}
                />
                <Card.Body>
                    <Card.Title as="div" className="h4">
                        {title}
                    </Card.Title>
                    {subtitle && <Card.Subtitle>{subtitle}</Card.Subtitle>}
                    {summary && <Card.Text>{summary}</Card.Text>}
                </Card.Body>
            </Stack>
        </Card>
    )
}

CardLink.defaultProps = {
    horizontal: false,
}

CardLink.propTypes = {
    horizontal: PropTypes.bool.isRequired,
    image: PropTypes.string,
    title: PropTypes.node.isRequired,
    href: PropTypes.node.isRequired,
    subtitle: PropTypes.node,
    summary: PropTypes.node,
}

export default CardLink
